@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
body{
    font-family: 'Inter';
    font-weight: 400;
}